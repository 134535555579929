<template>
  <div style="background-color: #EFEFF4;" class="h-full">
    <div class="fixed top-0 left-0 bg-white h-16 w-full z-20">
      <search-view class="">
        <template #search>
          <b-search-address
            @click="onNewSearch"
          ></b-search-address>
        </template>
      </search-view>
    </div>

    <div class="relative mt-16 bg-white z-10" style="box-shadow:0 1px 6px rgba(32,33,36,.13);">
      <b-results-header></b-results-header>

      <b-results-tab></b-results-tab>
    </div>

    <div class="relative bg-white min-h-screen mt-3 pt-3 overflow-auto">
      <div class="relative flex items-center px-5  border-b border-border border-opacity-50 pb-5">
        <div class="relative w-19/24 h-full">
          <div class="flex justify-between items-center">
            <h1 class="font-bold text-xl">
              Grégoire Beaulieu 
            </h1>

            <img 
              class="h-5 mr-2"
              :src="dots"
            >
          </div>

          <h2 class="text" style="color: #8a8a8d;">
            Homme politique
          </h2>

          <button 
            class="mt-5 py-1 px-4 rounded-full"
            style="color: #3F374F;background-color: #E7E7EA;border: 1px solid #3F374F;"
          >
            Aperçu 
          </button>
        </div>

        <div class="h-full w-5/24 flex justify-end items-center">
          <img 
            class="max-w-14 w-auto rounded-lg"
            :src="profil"
          >
        </div>
      </div>

      <div class="relative px-5 mt-3 border-b border-border border-opacity-50 pb-5">
        <p style="font-size: 0.8rem;">
          Grégoire&nbsp;Beaulieu,&nbsp;né&nbsp;le&nbsp;24&nbsp;décembre&nbsp;1988&nbsp;à&nbsp;Angoulême,
          <br>
          est un homme politique français. Il étudie à Oxford et à
          <br>
          Berkeley avant de revenir à Paris où il s'implique au sein 
          <br>
          de la direction de son parti l'Union des Républicains. 
          <br>
          Il se présente aux élections législatives de la première 
          <br>
          circonscription de la Charente dont dépend la ville 
          <br>
          d'Angoulême. <span style="color: #4285F4;">Cyclopédia</span>
          
          <br><br>

          Date / Lieu de naissance : <b>24 décembre 1988</b>
          <br>
          <span style="color: #4285F4;">Angoulême (16000)</span>

          <br><br>

          Parti : 
          <span style="color: #4285F4;">Union des Républicains</span>

          <br><br>

          Enseignement : 
          <span style="color: #4285F4;">University of Oxford, University of California Berkeley</span>
        </p>
      </div>
    </div>
  </div>
  <!-- <div class="google-results"> -->
    

    <!-- <b-results-tab></b-results-tab> -->

    <!-- <div class="results-page"> -->
      <!-- <results class="results-page-items"></results>

      <div class="results-page-highlight">
        <personality-profile></personality-profile>
      </div> -->
    <!-- </div> -->
  <!-- </div> -->
</template>

<script>
  import { defineAsyncComponent } from 'vue'
  import { useRouter } from 'vue-router'
  import Profil from '@assets/images/profil.png'
  import dots from '@assets/images/dots.png'

  export default {
    setup () {
      const router = useRouter()

      function onNewSearch () {
        console.log(`SEARCH`)
        router.replace({
          name: 'search-favoris',
        })
      }

      return {
        onNewSearch,
        profil: Profil,
        dots,
      }
    },

    components: {
      SearchView: defineAsyncComponent({
        loader: () => import(/* webpackChunkName: 'google' */'../search/search-view.vue'),
        suspensible: false,
        timeout: 3000, 
      }),
    
      Results: defineAsyncComponent({
        loader: () => import(/* webpackChunkName: 'google' */'./results.vue'),
        suspensible: false,
        timeout: 3000, 
      }),

      PersonalityProfile: defineAsyncComponent({
        loader: () => import(/* webpackChunkName: 'google' */'./personality-profile.vue'),
        suspensible: false,
        timeout: 3000, 
      }),
    }
  }
</script>

<style lang="scss">
  .google-results {
    padding-top: 72px;
  }

  .results-page {
    position: relative;
    display: flex;
    width: 100%;
    padding-top: 54px;
    padding-left: 140px;
    
    &-items {
      max-width: 652px;
      width: 100%;
    }

    &-highlight {
      max-width: 366px;
      margin-left: 64px;
    }
  }
</style>