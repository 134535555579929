<template>
  <div class="google-personality-wrapper w-full">
    <div 
      class="content p-0 rounded-lg w-full"
      style="border: 1px solid #dadce0;"
    >
      <header 
        class="w-full h-40 flex"
        style="border-bottom: 1px solid #dadce0;"
      >
        <div class="h-full flex justify-center flex-col flex-1 pl-5">
          <h1 class="text-2xl font-semibold">
            {{ dataset.personality.title }}
          </h1>

          <h2 class="text-xl">
            {{ dataset.personality.subtitle }}
          </h2>
        </div>

        <div 
          class="w-32 h-full bg-cover bg-center bg-no-repeat"
          :style="{
            backgroundImage: `url(${dataset.personality.image})`
          }"
        >

        </div>
      </header>

      <div class="p-5">
        <p 
          class="mb-5"
          v-html="dataset.personality.description"
        ></p>

        <p 
          v-if="dataset.personality.birthday"
          class="mb-2"
        >
          <b>Date / lieu de naissance :</b> {{ dataset.personality.birthday }}
        </p>

        <p 
          v-if="dataset.personality.record_company"
          class="mb-2"
        >
          <b>Maison de disque :</b> <a href="#" style="color: #1a0dab;">{{ dataset.personality.record_company }}</a>
        </p>

        <div 
          v-if="dataset.personality.songs"
          class="mt-10"
        >
          <h3 class="text-2xl">
            Titres
          </h3>

          <div
            v-for="song in dataset.personality.songs"
            :key="song._id"
            class="flex items-center h-20 my-2 pb-2"
            style="border-bottom: 1px solid #dadce0;"
          >
            <div class="flex-1">
              <h1 class="text-lg">
                {{ song.title }}
              </h1>

              <p class="text-lg" style="color: #70757a;">
                <small>{{ song.album }} - {{ song.year }}</small>
              </p>
            </div>

            <div 
              class="w-40 h-full bg-cover bg-center bg-no-repeat"
              :style="{
                backgroundImage: `url(${song.image})`
              }"
            >

            </div>
          </div>

          <p class="mt-3" style="color: #70757a;">
            Voir d'autres éléments (plus de 25)
          </p>
        </div>

        <div 
          v-if="dataset.personality.albums"
          class="mt-10"
        >
          <h3 class="text-2xl mb-2">
            Albums
          </h3>

          <div class="flex items-start flex-wrap">
            <div
              v-for="album in dataset.personality.albums"
              :key="album._id"
              class="pr-10"
            >
              <h1 class="text-lg">
                <a href="#" style="color: #1a0dab;">{{ album.title }}</a>
              </h1>

              <p class="text-lg" style="color: #70757a;">
                <small>{{ album.year }}</small>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { computed, inject, reactive } from 'vue'
  import { useStore } from 'vuex'

  export default {
    setup () {
      const store = useStore()

      return {
        dataset: inject('dataset'),
      }
    },
  }
</script>

<style lang="scss" scoped>


// Specify width
.content {
  width: 600px;
}

// Snippet
.snippet {
  width: 600px;
  margin-bottom: 26px;
  box-sizing: border-box;
}

.title { // Title
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 550px;
  
  a {
    color: #1a0dab;
    font-weight: normal;
    text-decoration: none;
    
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.url-breadcrumb { // Url or breadcrumb
  height: 18px;
  margin: 1px 0 0;
  line-height: 16px;
  white-space: nowrap;
  
  cite {
    font-size: 14px;
    color: #006621;
    font-style: normal;
  }
  
  .arrow-down {
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    text-align: center;
    
    &:after {
      content: "";
      display: inline-block;
      border-color: #006621 transparent;
      border-width: 5px 4px 0 4px;
      border-style: solid;
      vertical-align: middle;
      margin: -2px 0 0;
    }
  }
}

.microdata { // Microdata
  color: #808080;
  line-height: 18px;
  font-size: 13px;
  
  .stars-wrapper {
    margin: -4px 1px 0;
    width: 65px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    background-position: 0 50%;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTQgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+RGVza3RvcCAvIEljb24gLyBTdGFyIC8gU3RhciBhY3RpdmUgNiBDb3B5PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlNrdS1Qcm9kdWN0LUluZm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IjE1MDBweC0vLVNrdS0vLUxheW91dC0vLc6azrnOvc63z4TOsS3OpM63zrvOtc+Gz4nOvc6xLUNvcHktMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUxNi4wMDAwMDAsIC0yMTkuMDAwMDAwKSIgZmlsbD0iI0RERERERCI+ICAgICAgICAgICAgPGcgaWQ9IlByb2R1Y3QtSW5mby1Db3B5LTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQxMi41MDAwMDAsIDE3NC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmtzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjUwMDAwMCwgMzguNTAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iUmF0aW5nLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNTAwMDAwLCAxLjUwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgICAgIDxnIGlkPSJSYXRpbmctYmlnLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwLjAwMDAwMCwgNS4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTY5LjQzNjI0MTksMCBMNjcuMTEwMDg4MSw0LjYxNTM4NDYyIEw2Mi40OTQ3MDM1LDQuOTIxMTUzODUgTDY1Ljk1MTYyNjYsOC44MjgwNzY5MiBMNjQuODU2NjI2NiwxMy44NDYxNTM4IEw2OS40MDczOTU4LDExLjA3NTc2OTIgTDc0LjAzMzE2NSwxMy44NDYxNTM4IEw3Mi44NjMxNjUsOC44MjgwNzY5MiBMNzYuMzQwODU3Myw0Ljg0OTYxNTM4IEw3MS43MjY2MjY2LDQuNjE1Mzg0NjIgTDY5LjQzNjI0MTksMCIgaWQ9IkRlc2t0b3AtLy1JY29uLS8tU3Rhci0vLVN0YXItYWN0aXZlLTYtQ29weSI+PC9wYXRoPiAgICAgICAgICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==");
    background-size: 13px;
    background-repeat: repeat-x;
    
    > span {
      height: inherit;
      width: 80%;
      display: inline-block;
      background-position: inherit;
      background-size: inherit;
      background-repeat: inherit;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEzcHgiIGhlaWdodD0iMTNweCIgdmlld0JveD0iMCAwIDEzIDEzIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zICgxMTk3MCkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+U2hhcGU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBza2V0Y2g6dHlwZT0iTVNQYWdlIj4KICAgICAgICA8ZyBpZD0ib3JhbmdlLXN0YXIiIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIGZpbGw9IiNGNjhCMjQiPgogICAgICAgICAgICA8ZyBpZD0iUGFnZS0xIiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJzdGFyX2JpZyI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTYuNSwwLjQgTDguNSw0LjQgTDEyLjUsNC42IEw5LjUsOCBMMTAuNSwxMi4zIEw2LjUsOS45IEwyLjYsMTIuMyBMMy41LDggTDAuNSw0LjYgTDQuNSw0LjMgTDYuNSwwLjQiIGlkPSJTaGFwZSI+PC9wYXRoPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
    }
  }
}

.description { // Description
  font-size: 13px;
  color: #545454;
  line-height: 1.4;
  word-wrap: break-word;
  
  em { // Emphasis
    color: #6a6a6a;
    font-weight: bold;
    font-style: normal;
  }
  
  .date { // Date
    color: #808080;
  }
}


.mobile {
  // resize: both;
  // overflow: auto;
  font-family: 'Roboto', sans-serif;
  
  // Wrapper
  &.google-results-wrapper {
    background: #f2f2f2;
    padding: 10px 8px;
    width: 320px;
  }
  
  // Content
  .content {
    padding: 0;
    width: 100%;
  }
  
  // Snippet
  .snippet {
    font-size: 14px;
    line-height: 20px;
    width: auto;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    border-radius: 2px;
    margin: 0 0 10px 0;
    padding: 11px 16px 11px 16px;
  }
  
  .title { // Title
    font-size: 16px;
    white-space: normal;
  }

  .url-breadcrumb { // Url & breadcrumb
    padding: 11px 16px;
    border-bottom: 1px solid #ebebeb;
    line-height: 20px;
    margin: 0 -16px 9px -16px;
    padding: 0 16px 11px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .description { // Description
    color: #222;
    
    .date {
      color: #777;
    }
  }
}

// Toggle between Desktop & Mobile view

.toggler {
  margin:  15px auto auto;
  font-family: Roboto, san-serif;
  
  a {
    color: #bbb;
  }
  
  .desktop {
    color: #1a0dab;
  }
  
  .mobile {
    
    .desktop {
      color: #bbb;    
    }

    .mobile {
      color: #1a0dab;
    }
  }
}
</style>