<template>
  <div class="favoris h-full pt-5 px-5">
    <header class="flex items-center justify-between">
      <h1 class="font-medium text-lg pl-2">
        Favoris
      </h1>

      <button class="font-medium text-lg pr-2">
        Moins
      </button>
    </header>
    
    <section class="flex justify-start items-center flex-wrap">
      <div 
        v-for="lien in favoris"
        :key="lien.image"
        class="w-6/24 flex items-center flex-col my-2"
      >
        <img 
          :src="lien.image"
          class="max-w-full"
        >

        <h2 class="text-center font-bold text-xs ">
          {{ lien.name }}
        </h2>
      </div>
    </section>
  </div>
</template>

<script>
  import { computed } from 'vue'
  
  export default {
    setup () {
      const favoris = computed(() => {
        return [
          { image: '/images/jumeaux/lemonde.jpg', name: 'Le Monde' },
          { image: '/images/jumeaux/cyclopedia.jpg', name: 'Cyclopédia' },
          { image: '/images/jumeaux/instagram.jpg', name: 'Instagram' },
          { image: '/images/jumeaux/sns.jpg', name: 'SNS' },
          { image: '/images/jumeaux/zaizai.jpg', name: 'ZAÏZAÏ RADIO'},
          { image: '/images/jumeaux/pxl.jpg', name: 'Pxlcrew' },
          { image: '/images/jumeaux/canal.jpg', name: 'myCANAL' },
          { image: '/images/jumeaux/rachtaia.jpg', name: 'Rachtaia' },
          { image: '/images/jumeaux/sirenes.jpg', name: 'Les Sirènes' },
        ]
      })

      return {
        favoris,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .favoris {
    position: relative;
    min-height: calc(100vh - 4rem); 
    background-color: #EFEFF4;
  }

  h1 {
    font-weight: 700;
    letter-spacing: 0px;
  }

  button {
    color: #007aff;
  }
</style>