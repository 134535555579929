<template>
  <div class="safari-search">
    <search-view>
      <template #search>
        <b-search-form
          @search="onSearch"
          @searching="onSearching"
        ></b-search-form>
      </template>
    </search-view>
    
    <div class="safari-results relative h-full">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent, ref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'

  export default {
    setup () {
      const router = useRouter()
      const route = useRoute()

      let autocomplete = ref(false)

      function onSearch (event) {
        const input = event.target.querySelector('input[title="Rechercher"]')

        const query = {
          ...route.query,
          query: input.value,
        }

        router.push({
          name: 'google-results',
          query: query
        })
      }

      function onSearching (event) {
        const input = event.target

        if (!autocomplete.value && input.value.length) {
        
          if (input.value.length > 4 && /^gr(é|e)/gi.test(input.value)) {
            autocomplete.value = true 

            router.push({
              name: 'search-autocomplete',
              query: {
                q: input.value
              }
            })
          }

          console.log(`searching`)
        } else if (!input.value.length && autocomplete.value) {
          autocomplete.value = false

          router.replace({
            name: 'search-favoris',
          })
        } else if (route.name === 'search-autocomplete') {
          router.replace({
            name: 'search-autocomplete',
            query: {
              q: input.value
            }
          })
        }
      }

      return {
        onSearch,
        onSearching,
      }
    },

    components: {
      SearchView: defineAsyncComponent({
        loader: () => import(/* webpackChunkName: 'google' */'./search-view.vue'),
        suspensible: false,
        timeout: 3000, 
      }),
    }
  }
</script>

<style lang="scss">
  .safari-results {
    min-height: calc(100vh - 4rem);
    max-height: calc(100vh - 4rem);
    overflow: auto; 
  }
</style>