<template>
  <div class="google-results-wrapper">
    <div class="content">
      <div 
        v-if="dataset.videos.search_tags.length"
        class="google-results-videos"
      >
        <header class="flex items-center mb-5">
          <svg focusable="false" viewBox="0 0 24 24" width="24" height="24">
            <path
              fill="#70757a" 
              d="M10 16.5l6-4.5-6-4.5v9zM5 20h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1zm14.5 2H5a3 3 0 0 1-3-3V4.4A2.4 2.4 0 0 1 4.4 2h15.2A2.4 2.4 0 0 1 22 4.4v15.1a2.5 2.5 0 0 1-2.5 2.5"></path>
          </svg>

          <h1 class="ml-4 text-xl">Vidéos</h1>
        </header>

        <div 
          class="flex items-center mb-3 pb-3"
          style="border-bottom: 1px solid #dadce0;"
        >
          <button 
            v-for="tag in dataset.videos.search_tags"
            :key="tag"
            class="outline outline-none rounded-full mx-2 px-2 py-1"
            style="border: 1px solid #dadce0;"
          >
            {{ tag }}
          </button>
        </div>

        <div>
          <b-result-highlight-youtube
            v-for="result in dataset.videos.search_videos"
            :key="result._id"
            :result="result"
            style="margin-bottom: 20px;"
          ></b-result-highlight-youtube>
        </div>

         <div 
          class="relative flex items-center my-12"
          style="border-bottom: 1px solid #dadce0;"
        >
          <button 
            class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 outline outline-none rounded-full mx-2 px-2 py-1"
            style="border: 1px solid #dadce0; background: white; width: 300px; height: 36px; color: #1a0dab; font-weight: 500;"
          >
            Tout afficher
          </button>
        </div>
      </div>

      <component
        v-for="result in state.results"
        :key="result.title"
        :result="result"
        :is="result.type"
        style="margin-bottom: 20px;"
      ></component>

      <p v-if="state.isLoading">
        Chargement en cours...
      </p>

      <div class="flex justify-center mb-32">
        <img src="@assets/images/pagination.png">
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, inject, reactive } from 'vue'
  import { useStore } from 'vuex'

  export default {
    setup () {
      const store = useStore()

      const state = reactive({
        results: computed(() => store.state.google.results),
        latency: computed(() => store.state.google.latency),
        delay: computed(() => store.state.google.delay),
        fetchedResults: [],
        isLoading: false,
      })

      function fetchResults () {
        setTimeout(() => {
          if (state.fetchedResults.length !== state.results.length) {
            state.fetchedResults.push(
              ...state.results.slice(
                state.fetchedResults.length,
                state.fetchedResults.length + 2
              )
            )

            if (Math.random() > 0.5) {
              store.commit('google/incrementLatency', Math.random())
            } else {
              store.commit('google/decrementLatency', Math.random())
            }
            
            fetchResults()
          } else {
            state.isLoading = false
          }
        }, state.latency * 1000)
      }

      // fetchResults()

      return {
        dataset: inject('dataset'),
        state,
      }
    },
  }
</script>

<style lang="scss" scoped>

// Align center & middle
.google-results-wrapper {
  // margin: auto auto 10px;
}

// Specify width
.content {
  width: 600px;
  padding: 0 16px;
}

// Snippet
.snippet {
  width: 600px;
  margin-bottom: 26px;
  box-sizing: border-box;
}

.title { // Title
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 550px;
  
  a {
    color: #1a0dab;
    font-weight: normal;
    text-decoration: none;
    
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.url-breadcrumb { // Url or breadcrumb
  height: 18px;
  margin: 1px 0 0;
  line-height: 16px;
  white-space: nowrap;
  
  cite {
    font-size: 14px;
    color: #006621;
    font-style: normal;
  }
  
  .arrow-down {
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    text-align: center;
    
    &:after {
      content: "";
      display: inline-block;
      border-color: #006621 transparent;
      border-width: 5px 4px 0 4px;
      border-style: solid;
      vertical-align: middle;
      margin: -2px 0 0;
    }
  }
}

.microdata { // Microdata
  color: #808080;
  line-height: 18px;
  font-size: 13px;
  
  .stars-wrapper {
    margin: -4px 1px 0;
    width: 65px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    background-position: 0 50%;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxNHB4IiB2aWV3Qm94PSIwIDAgMTQgMTQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+RGVza3RvcCAvIEljb24gLyBTdGFyIC8gU3RhciBhY3RpdmUgNiBDb3B5PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlNrdS1Qcm9kdWN0LUluZm8iIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9IjE1MDBweC0vLVNrdS0vLUxheW91dC0vLc6azrnOvc63z4TOsS3OpM63zrvOtc+Gz4nOvc6xLUNvcHktMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTUxNi4wMDAwMDAsIC0yMTkuMDAwMDAwKSIgZmlsbD0iI0RERERERCI+ICAgICAgICAgICAgPGcgaWQ9IlByb2R1Y3QtSW5mby1Db3B5LTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQxMi41MDAwMDAsIDE3NC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9IkxpbmtzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjUwMDAwMCwgMzguNTAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iUmF0aW5nLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNTAwMDAwLCAxLjUwMDAwMCkiPiAgICAgICAgICAgICAgICAgICAgICAgIDxnIGlkPSJSYXRpbmctYmlnLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwLjAwMDAwMCwgNS4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTY5LjQzNjI0MTksMCBMNjcuMTEwMDg4MSw0LjYxNTM4NDYyIEw2Mi40OTQ3MDM1LDQuOTIxMTUzODUgTDY1Ljk1MTYyNjYsOC44MjgwNzY5MiBMNjQuODU2NjI2NiwxMy44NDYxNTM4IEw2OS40MDczOTU4LDExLjA3NTc2OTIgTDc0LjAzMzE2NSwxMy44NDYxNTM4IEw3Mi44NjMxNjUsOC44MjgwNzY5MiBMNzYuMzQwODU3Myw0Ljg0OTYxNTM4IEw3MS43MjY2MjY2LDQuNjE1Mzg0NjIgTDY5LjQzNjI0MTksMCIgaWQ9IkRlc2t0b3AtLy1JY29uLS8tU3Rhci0vLVN0YXItYWN0aXZlLTYtQ29weSI+PC9wYXRoPiAgICAgICAgICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==");
    background-size: 13px;
    background-repeat: repeat-x;
    
    > span {
      height: inherit;
      width: 80%;
      display: inline-block;
      background-position: inherit;
      background-size: inherit;
      background-repeat: inherit;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEzcHgiIGhlaWdodD0iMTNweCIgdmlld0JveD0iMCAwIDEzIDEzIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zICgxMTk3MCkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+U2hhcGU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBza2V0Y2g6dHlwZT0iTVNQYWdlIj4KICAgICAgICA8ZyBpZD0ib3JhbmdlLXN0YXIiIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIGZpbGw9IiNGNjhCMjQiPgogICAgICAgICAgICA8ZyBpZD0iUGFnZS0xIiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJzdGFyX2JpZyI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTYuNSwwLjQgTDguNSw0LjQgTDEyLjUsNC42IEw5LjUsOCBMMTAuNSwxMi4zIEw2LjUsOS45IEwyLjYsMTIuMyBMMy41LDggTDAuNSw0LjYgTDQuNSw0LjMgTDYuNSwwLjQiIGlkPSJTaGFwZSI+PC9wYXRoPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=");
    }
  }
}

.description { // Description
  font-size: 13px;
  color: #545454;
  line-height: 1.4;
  word-wrap: break-word;
  
  em { // Emphasis
    color: #6a6a6a;
    font-weight: bold;
    font-style: normal;
  }
  
  .date { // Date
    color: #808080;
  }
}

.mobile {
  // resize: both;
  // overflow: auto;
  font-family: 'Roboto', sans-serif;
  
  // Wrapper
  &.google-results-wrapper {
    background: #f2f2f2;
    padding: 10px 8px;
    width: 320px;
  }
  
  // Content
  .content {
    padding: 0;
    width: 100%;
  }
  
  // Snippet
  .snippet {
    font-size: 14px;
    line-height: 20px;
    width: auto;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    border-radius: 2px;
    margin: 0 0 10px 0;
    padding: 11px 16px 11px 16px;
  }
  
  .title { // Title
    font-size: 16px;
    white-space: normal;
  }

  .url-breadcrumb { // Url & breadcrumb
    padding: 11px 16px;
    border-bottom: 1px solid #ebebeb;
    line-height: 20px;
    margin: 0 -16px 9px -16px;
    padding: 0 16px 11px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .description { // Description
    color: #222;
    
    .date {
      color: #777;
    }
  }
}

// Toggle between Desktop & Mobile view

.toggler {
  margin:  15px auto auto;
  font-family: Roboto, san-serif;
  
  a {
    color: #bbb;
  }
  
  .desktop {
    color: #1a0dab;
  }
  
  .mobile {
    
    .desktop {
      color: #bbb;    
    }

    .mobile {
      color: #1a0dab;
    }
  }
}
</style>