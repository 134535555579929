<template>
  <div>
    <section>
      <header class="py-1">
        <h1 class="font-bold px-5 text-lg">
          Connaissances Siri
        </h1>
      </header>

      <div class="flex items-center px-5">
        <router-link 
          class="h-full w-4/24 flex justify-start items-center"
          :to="{ name: 'results' }"
        >
          <img 
            class="max-w-14 w-auto rounded-lg"
            src="/images/jumeaux/profil.png" 
          >
        </router-link>

        <div class="w-20/24 pl-5">
          <h2 class="font-bold text-black text-xl">
            Grégoire Beaulieu
          </h2>

          <p 
            class="text"
            style="color: #8a8a8d; font-size: 0.8rem;"
          >
            Grégoire&nbsp;Beaulieu,&nbsp;né&nbsp;le&nbsp;24&nbsp;décembre&nbsp;1988&nbsp;à
            <br>
            Angoulême,&nbsp;est&nbsp;un&nbsp;homme&nbsp;politique&nbsp;français. 
          </p>

          <footer>
            <small style="color: #c8c8ca;">Cyclopédia</small>
          </footer>
        </div>
      </div>
    </section>

    <section>
      <header class="py-1">
        <h1 class="font-bold px-5 text-lg">
          Recherche
        </h1>
      </header>

      <div class="px-5">
        <ul>
          <li class="flex items-center w-full pb-2 mb-2 border-b border-border border-opacity-50">
            <img
              class="h-7"
              :src="loupe"
            > 
            
            <p class="text-lg">
              <router-link 
                class="results-link"
                :to="{ name: 'results' }"
              >
                {{ $route.query.q }}
              </router-link> 
            </p> 
          </li>

          <li class="flex items-center w-full pb-2 mb-2 border-b border-border border-opacity-50">
            <img
              class="h-7"
              :src="loupe"
            > 
            
            <p class="text-lg">
              <router-link 
                class="results-link"
                :to="{ name: 'results' }"
              >
                gregoire beaulieu 
              </router-link> 
            </p> 
          </li>

          <li class="flex items-center w-full pb-2 mb-2 border-b border-border border-opacity-50">
            <img
              class="h-7"
              :src="loupe"
            > 
            
            <p class="text-lg">
              <router-link 
                class="results-link"
                :to="{ name: 'results' }"
              >
                gregoire beaulieu femme 
              </router-link> 
            </p> 
          </li>

          <li class="flex items-center w-full pb-2 mb-2 border-b border-border border-opacity-50">
            <img
              class="h-7"
              :src="loupe"
            > 
            
            <p class="text-lg">
              <router-link 
                class="results-link"
                :to="{ name: 'results' }"
              >
                gregoire beaulieu udr
              </router-link> 
            </p> 
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
  import Loupe from '@assets/images/loupe.png'
  
  export default {
    setup () {
      
      return {
        loupe: Loupe,
      }
    },
  }
</script>

<style lang="scss" scoped>
  header {
    background: #e5e5e5;
    margin-bottom: 1.2rem;
  }

  section {
    padding-bottom: 1.2rem;
  }

  .results-link {
    color: black;
    
    &:active, &:visited, &:focus, &:hover {
      color: black;
    }
  }
</style>